import React from "react";
import Sidebar from '../../pages/Include/Sidebar';
import Header from '../../pages/Include/Header';

const Layout = ({ children }) => {

    return (
        <>
      
            <div>
                <Header />
                    <Sidebar />
                <div className='main'>
                    <div className='maincontent_div'>
                        {children}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Layout;