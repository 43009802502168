import React, { useState, useEffect } from "react";
import { NavLink, Link, useNavigate, useLocation } from "react-router-dom";
import opentogglebar from "../../../assets/image/togglebar.svg";
import Dashboard from "../../../assets/image/sidebardashboard_icon.svg";
import Dashboard2 from "../../../assets/image/sidebardashboard_icon2.svg";
import Library from "../../../assets/image/sidebarlibrary_icon.svg";
import Library2 from "../../../assets/image/sidebarlibrary_icon2.svg";
import mealplan from "../../../assets/image/sidebarmealplan_icon.svg";
import mealplan2 from "../../../assets/image/sidebarmealplan_icon2.svg";
import AccountSettings from "../../../assets/image/sidebarsetting_icon.svg";
import AccountSettings2 from "../../../assets/image/sidebarsetting_icon2.svg";
import Users from "../../../assets/image/users.svg";
import Users2 from "../../../assets/image/users2.svg";
import Chats from "../../../assets/image/chats.svg";
import Chats2 from "../../../assets/image/chats2.svg";
import Reports from "../../../assets/image/reports.svg";
import Reports2 from "../../../assets/image/reports2.svg";
import logoimg from "../../../assets/image/logo.png";

const Sidebar = () => {
  const navigate = useNavigate();
  const [openDropdowns, setOpenDropdowns] = useState([]);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    const path = pathname.split("/")[1];
    console.log(path)
    
    if (
      path === "ChangePassword" ||
      path === "ProfileSetting" ||
      path === "Notifications"
    ) {
      setOpenDropdowns("AccountSettings");
    }
    if (
      path === "Addvideo" ||
      path === "Videos" ||
      path === "add-category" ||
      path === "questionnaire-list" ||
      path === "categories"
    ) {
      setOpenDropdowns("Library");
    }
  }, [pathname]);
  const toggleDropdown = (dropdown) => {
    if (openDropdowns === dropdown.id) {
      setOpenDropdowns(null); // Close the dropdown if it's already open
    } else {
      setOpenDropdowns(dropdown.id);
      navigate(dropdown.links[0].link);
    }
  };
  // const toggleHoverDropdown = (dropdown) => {
  //   if (openHoverDropdowns === dropdown.id) {
  //     setOpenHoverDropdowns(null); // Close the dropdown if it's already open
  //   } else {
  //     setOpenHoverDropdowns(dropdown.id);
  //   }
  // };

  const closeAllDropdowns = () => {
    setOpenDropdowns([]);
  };

  const handleOutsideClick = (event) => {
    if (openDropdowns !== null && !event.target.closest(".dropdown")) {
      closeAllDropdowns();
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [openDropdowns]);

  useEffect(() => {
    const handleResize = () => {
      const width = window.innerWidth;
      if (width < 768) {
        setSidebarOpen(false);
      } else {
        setSidebarOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  React.useEffect(() => {
    document.body.classList.toggle("no-scroll", sidebarOpen);
  }, [sidebarOpen]);
  const handleNavLinkClick = () => {
    setSidebarOpen(false);
    closeAllDropdowns();
    if (!sidebarOpen) {
      toggleSidebar();
    }
  };
  const dropdownData = [
    {
      id: "Dashboard",
      title: "Dashboard",
      sidebaricon: Dashboard,
      sidebaricon2: Dashboard2,
      link: "/Dashboard",
      isDropdown: false,
    },
    {
      id: "Users",
      title: "Users",
      sidebaricon: Users,
      sidebaricon2: Users2,
      isDropdown: false,
      link: "/Users",
    },
    {
      id: "OrganizationUsers",
      title: "Admin Users",
      sidebaricon: Users,
      sidebaricon2: Users2,
      isDropdown: false,
      link: "/organization-users",
    },
    {
      id: "Role",
      title: "Role",
      sidebaricon: Users,
      sidebaricon2: Users2,
      isDropdown: false,
      link: "/role",
    },
    {
      id: "Library",
      title: "Library",
      sidebaricon: Library,
      sidebaricon2: Library2,
      isDropdown: true,
      links: [
        {
          id: "Videos",
          label: "Videos",
          link: "/Videos",
        },
        {
          id: "Recipes",
          label: "Recipes",
          link: "#",
        },
        {
          id: "Questionnaires",
          label: "Questionnaires",
          link: "/questionnaire-list",
        },
        {
          id: "QuestionnaireCategory",
          label: "Questionnaire Category",
          link: "/categories",
        },
      ],
    },
    {
      id: "MealPlans",
      title: "Meal Plans",
      sidebaricon: mealplan,
      sidebaricon2: mealplan2,
      isDropdown: false,
      link: "/Mealplan",
    },
    {
      id: "ActionPlans",
      title: "Action Plans",
      sidebaricon: mealplan,
      sidebaricon2: mealplan2,
      isDropdown: false,
      link: "/action-plan",
    },
    {
      id: "Community",
      title: "My Community",
      sidebaricon: mealplan,
      sidebaricon2: mealplan2,
      isDropdown: false,
      link: "/newcommunity",
    },

    {
      id: "Reports",
      title: "Reports",
      sidebaricon: Reports,
      sidebaricon2: Reports2,
      isDropdown: false,
      link: "javascript:void(0)",
    },
    // {
    //   id: "chat",
    //   title: "Chat",
    //   sidebaricon: Chats,
    //   sidebaricon2: Chats2,
    //   isDropdown: false,
    //   link: "/Chat",
    // },
    {
      id: "AccountSettings",
      title: "Account Settings",
      sidebaricon: AccountSettings,
      sidebaricon2: AccountSettings2,
      isDropdown: true,
      links: [
        {
          id: "Profile",
          label: "Profile",
          link: "/ProfileSetting",
        },
        {
          id: "Changepassword",
          label: "Change Password",
          link: "/ChangePassword",
        },
        {
          id: "Notifications",
          label: "Notifications",
          link: "/Notifications",
        },
      ],
    },
  ];
  // const handleMouseHover = (dropdown) => {
  //   if (dropdown.isDropdown) {
  //     setHoverDropdowns(dropdown.id);
  //     toggleHoverDropdown(dropdown);
  //   }
  // };
  // const handleMouseLeave = (dropdown) => {
  //   if (dropdown.isDropdown) {
  //     setHoverDropdowns(null);
  //     toggleHoverDropdown(dropdown);
  //   }
  // };
  return (
    <>
      <button
        onClick={toggleSidebar}
        className="btnopen"
        style={{ display: "none" }}
      >
        <img src={opentogglebar} alt="" />
      </button>
      <button
        className={
          sidebarOpen ? "btnclose sidebarbg-open" : "btnclose sidebarbg-closed"
        }
        onClick={toggleSidebar}
        style={{ display: "none" }}
      ></button>
      <div
        className={
          sidebarOpen ? "sidebar sidebar-open" : "sidebar sidebar-closed"
        }
      >
        <div className="sidebarlogo_div">
          <Link to="/Dashboard">
            <img src={logoimg} alt="" />
          </Link>
        </div>
        <ul>
          {dropdownData.map((dropdown) => (
            <li
              key={dropdown.id}
              className={`dropdown ${
                openDropdowns === dropdown.id ? "open" : ""
              }`}
              // onMouseEnter={() => handleMouseHover(dropdown)}
              // onMouseLeave={() => handleMouseLeave(dropdown)}
            >
              {dropdown.isDropdown ? (
                <>
                  <button
                    className="dropdown-toggle"
                    onClick={() => toggleDropdown(dropdown)}
                  >
                    <img
                      className="sidebaricon"
                      src={dropdown.sidebaricon}
                      alt=""
                    />
                    <img
                      className="sidebaractiveicon"
                      src={dropdown.sidebaricon2}
                      alt=""
                    />
                    {dropdown.title}
                  </button>
                  {openDropdowns === dropdown.id && (
                    <ul className="dropdown-links dropdown-menu">
                      {dropdown.links.map((link) =>
                        link.id !== "Recipes" ? (
                          <li key={link.id}>
                            <NavLink
                              to={link.link}
                              onClick={toggleSidebar}
                              className="dropdown-link"
                            >
                              {link.label}
                            </NavLink>
                          </li>
                        ) : (
                          <li key={link.id}>
                            <a
                              href="https://showcase.indiit.com/welfore/wp-admin/"
                              className="dropdown-link"
                              target="_blank"
                            >
                              {link.label}
                            </a>
                          </li>
                        )
                      )}
                    </ul>
                  )}
                  {/* {openHoverDropdowns === dropdown.id && (
                    <ul className="dropdown-links dropdown-menu">
                      {dropdown.links.map((link) => (
                        <li key={link.id}>
                          <NavLink
                            to={link.link}
                            onClick={toggleSidebar}
                            className="dropdown-link"
                          >
                            {link.label}
                          </NavLink>
                        </li>
                      ))}
                    </ul>
                  )} */}
                </>
              ) : (
                <NavLink
                  to={dropdown.link}
                  onClick={() => handleNavLinkClick(dropdown.id)}
                >
                  <img
                    className="sidebaricon"
                    src={dropdown.sidebaricon}
                    alt=""
                  />
                  <img
                    className="sidebaractiveicon"
                    src={dropdown.sidebaricon2}
                    alt=""
                  />
                  {dropdown.title}
                </NavLink>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
