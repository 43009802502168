import React, { useState, useEffect } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./config.js";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "nprogress/nprogress.css";
import "react-activity/dist/library.css";
import { useDispatch, useSelector } from "react-redux";
import { UpdateUser } from "./store/userSlice";
import { Helper } from "./helper/HelperFunction";
import { API_URL } from "./helper/API_URL";
import { Container } from "@material-ui/core/";
import { useMainPaperStyles } from "./styles/muiStyles";

import PublicRoute from "./routes/PublicRoute.js";
import PageLoader from "./components/PageLoader.js";
import PrivateRoute from "./routes/PrivateRoute.js";

function App() {
  const dispatch = useDispatch();
  const userDetails = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const userToken =
    window.localStorage.getItem("userToken") ||
    window.sessionStorage.getItem("userToken");
  useEffect(() => {
    if (userToken) {
      fetchToken();
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const fetchToken = async () => {
    try {
      const url = API_URL.PROFILE;
      const user = await Helper.GetData(url, { userToken });
      if (user.data.status) {
        dispatch(UpdateUser({ ...user.data.data, Usertoken: userToken }));
      } else {
        window.localStorage.removeItem("userToken");
      }
    } catch (err) {
      console.log(err);
      window.localStorage.removeItem("userToken");
    }
  };
  if (!userToken) {
    return (
      <>
        {loading ? (
          <PageLoader />
        ) : (
          <Router basename={process.env.REACT_APP_BASENAME}>
            <PublicRoute />
          </Router>
        )}
      </>
    );
  } else {
    return (
      <>
        {loading ? (
          <PageLoader />
        ) : (
          <Router basename={process.env.REACT_APP_BASENAME}>
            <PrivateRoute />
          </Router>
        )}
      </>
    );
  }
}

export default App;
