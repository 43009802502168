import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import PageLoader from "../components/PageLoader.js";
import Layout from "../components/Layout/index.js";

const ProfileSetting = lazy(() => import("../pages/ProfileSetting"));
const ChangePassword = lazy(() =>
  import("../pages/AccountSettings/ChangePassword")
);
const Mealplan = lazy(() => import("../pages/Mealplan"));
const Addmealplan = lazy(() => import("../pages/Mealplan/Addmealplan"));
const Addvideo = lazy(() => import("../pages/library/Videos/Addvideo"));
const Editmealplan = lazy(() => import("../pages/Mealplan/Editmealplan"));
const Editvideo = lazy(() => import("../pages/library/Videos/Editvideo"));
const Addrecipe = lazy(() => import("../pages/library/Recipes/Addrecipe"));
const Editrecipe = lazy(() => import("../pages/library/Recipes/Editrecipe"));
const Videos = lazy(() => import("../pages/library/Videos"));
const Notifications = lazy(() => import("../pages/Notifications"));
const Recipes = lazy(() => import("../pages/library/Recipes"));
const Dashboard = lazy(() => import("../pages/Dashboard"));
const Users = lazy(() => import("../pages/Users"));
const Adduser = lazy(() => import("../pages/Users/Adduser"));
const Edituser = lazy(() => import("../pages/Users/Edituser"));
const Diary = lazy(() => import("../pages/Users/Diary"));
const Questionary = lazy(() => import("../pages/Users/Questionery"));
const Adddietplan = lazy(() =>
  import("../pages/ActionPlan/Adddietplan/index.js")
);
const DietPlan = lazy(() => import("../pages/ActionPlan/index.js"));
const Editdietplan = lazy(() =>
  import("../pages/ActionPlan/Editdietplan/index.js")
);
const ListDietPlan = lazy(() =>
  import("../pages/ActionPlan/Listdietplan/index.js")
);
const Detailrecipe = lazy(() => import("../pages/library/Recipes/Detailrecipe"));
const Questions = lazy(() =>
  import("../pages/library/QuestionsManagment/Questions")
);
const ManageQuestion = lazy(() =>
  import("../pages/library/QuestionsManagment/ManageQuestion")
);
const Detailvideo = lazy(() => import("../pages/library/Videos/Detailvideo"));
const EditQuestion = lazy(() =>
  import("../pages/library/QuestionsManagment/EditQuestion.js")
);
const Detaildiet = lazy(() => import("../pages/ActionPlan/Detaildiet/index.js"));
const Detailmeal = lazy(() => import("../pages/Mealplan/Detailmeal"));
const QuestionList = lazy(() =>
  import("../pages/library/QuestionsManagment/QuestionList.js")
);
const CategoryList = lazy(() => import("../pages/library/Category/index.js"));
const Addcategory = lazy(() =>
  import("../pages/library/Category/Addcategory/index.js")
);
const Editcategory = lazy(() =>
  import("../pages/library/Category/Editcategory/index.js")
);
const Role = lazy(() => import("../pages/AccountSettings/Role/index.js"));
const AddRole = lazy(() =>
  import("../pages/AccountSettings/Role/Addrole/index.js")
);
const EditRole = lazy(() =>
  import("../pages/AccountSettings/Role/Editrole/index.js")
);
const Organizations = lazy(() => import("../pages/Organization/index.js"));
const AddOrganization = lazy(() =>
  import("../pages/Organization/Adduser/index.js")
);
const EditOrganization = lazy(() =>
  import("../pages/Organization/Edituser/index.js")
);
const ChangeUserPassword = lazy(() =>
  import("../pages/Users/ChangePassword/index.js")
);
const ViewUser = lazy(() => import("../pages/Users/ViewUser/ViewUser.js"));
const Category = lazy(() => import("../pages/Users/Questionery/category.js"));
const FoodDiary = lazy(() => import("../pages/Users/Questionery/FoodDiary.js"));
const SmartGoals = lazy(() =>
  import("../pages/Users/Questionery/SmartGoals.js")
);
const FoodPrefrence = lazy(() =>
  import("../pages/Users/Questionery/FoodPrefrence.js")
);
const LifeStyle = lazy(() => import("../pages/Users/Questionery/LifeStyle.js"));
const DietitianPrep = lazy(() => import("../pages/Users/Questionery/index.js"));
const CommunityPost = lazy(() =>
  import("../pages/newcommunity/CommunityPost.js")
);
const NewPost = lazy(() => import("../pages/newcommunity/NewPOST.js"));
const PostFormModal = lazy(() => import("../components/PostFormModal"));
const PostList = lazy(() => import("../components/PostList"));
const PostCommentsPage = lazy(() => import("../components/PostCommentsPage"));
const UserPage = lazy(() => import("../components/UserPage"));
const SubPage = lazy(() => import("../components/SubPage"));
const TopSubsPanel = lazy(() => import("../components/TopSubsPanel"));
const SearchResults = lazy(() => import("../components/SearchResults"));
const NotFoundPage = lazy(() => import("../components/NotFoundPage"));
const AddNotifiaction = lazy(() =>
  import("../pages/Notifications/AddNotifiaction.js")
);

const PrivateRoute = () => {
  return (
    <Layout>
      <Suspense fallback={<PageLoader />}>
        <Routes>
          <Route path="/ProfileSetting" element={<ProfileSetting />} />
          <Route path="/" element={<Dashboard />} />
          <Route path="/ChangePassword" element={<ChangePassword />} />
          <Route path="*" element={<Dashboard />} />
          <Route path="/Mealplan" element={<Mealplan />} />
          <Route path="/Addmealplan" element={<Addmealplan />} />
          <Route path="/detail-mealplan" element={<Detailmeal />} />
          <Route path="/Editmealplan" element={<Editmealplan />} />
          <Route path="/action-plan" element={<DietPlan />} />
          <Route path="/Listdietplan" element={<ListDietPlan />} />
          <Route path="/detail-diet" element={<Detaildiet />} />
          <Route path="/action-plan/add" element={<Adddietplan />} />
          <Route path="/Editdietplan" element={<Editdietplan />} />
          <Route path="/Addvideo" element={<Addvideo />} />
          <Route path="/Editvideo" element={<Editvideo />} />
          <Route path="/Addrecipe" element={<Addrecipe />} />
          <Route path="/Editrecipe" element={<Editrecipe />} />
          <Route path="/detail-recipe" element={<Detailrecipe />} />
          <Route path="/detail-video" element={<Detailvideo />} />
          <Route path="/Adduser" element={<Adduser />} />
          <Route path="/users/edit" element={<Edituser />} />
          <Route path="/diary" element={<Diary />} />
          <Route path="/questionary" element={<Questionary />} />
          <Route path="/Users" element={<Users />} />
          <Route path="/Users/view" element={<ViewUser />} />
          <Route path="/Notifications" element={<Notifications />} />
          <Route path="/Notifications/add" element={<AddNotifiaction />} />
          <Route path="/Videos" element={<Videos />} />
          <Route path="/Recipes" element={<Recipes />} />
          <Route path="/Questions" element={<Questions />} />
          <Route path="/ManageQuestion" element={<ManageQuestion />} />
          <Route path="/edit-question" element={<EditQuestion />} />
          <Route path="/questionnaire-list" element={<QuestionList />} />
          <Route path="/categories" element={<CategoryList />} />
          <Route path="/add-category" element={<Addcategory />} />
          <Route path="/edit-category" element={<Editcategory />} />
          <Route path="/role" element={<Role />} />
          <Route path="/add-role" element={<AddRole />} />
          <Route path="/edit-role" element={<EditRole />} />
          <Route path="/organization-users" element={<Organizations />} />
          <Route path="/add-organization-user" element={<AddOrganization />} />
          <Route
            path="/edit-organization-user"
            element={<EditOrganization />}
          />
          <Route
            path="//reset-user-password"
            element={<ChangeUserPassword />}
          />
          <Route path="/Category" element={<Category />} />
          <Route path="/foodDiary" element={<FoodDiary />} />
          <Route path="/smartGoals" element={<SmartGoals />} />
          <Route path="/FoodPrefrence" element={<FoodPrefrence />} />
          <Route path="/LifeStyle" element={<LifeStyle />} />
          <Route path="/DietitianPrep" element={<DietitianPrep />} />
          <Route path="/community" element={<PostList />} />
          <Route path="/newcommunity" element={<CommunityPost />} />
          <Route path="/NewPost" element={<NewPost />} />
        </Routes>
      </Suspense>
    </Layout>
  );
};

export default PrivateRoute;
