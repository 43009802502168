import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  Usertoken: "",
  gender: "",
  zipCode: "",
  deviceToken: "",
  accountStatus: "",
  _id: "",
  firstName: "",
  lastName: "",
  email: "",
  profilePic: "",
  mobileNumber: "",
  emailVerified: false,
  role: "",
  city: "",
  dob: "",
  state: ""
};
export const userSlice = createSlice({
    name:"userDetails",
    initialState,
    reducers:{
        UpdateUser:(state,action)=>{
            return {...state,...action.payload}
        },
        LogedOut:(state)=>{
            return {...state,...initialState};
        }
    }
})

export const {UpdateUser,LogedOut}=userSlice.actions;
export default userSlice.reducer;