import React, { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Collapse,
  Navbar,
  Input,
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import ReactCountryFlag from "react-country-flag";
import { User, LogOut, Search } from "react-feather";
import userimg from "../../../assets/image/img/user-9.jpg";
import { S3_URL } from "../../../helper/HelperFunction";
import { useDispatch, useSelector } from "react-redux";
import { LogedOut } from "../../../store/userSlice";

const Header = (args) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const userDetails = useSelector((state) => state.user);
  const [profileImg, setProfileImg] = useState(userDetails.profilePic);
  useEffect(() => {
    setProfileImg(userDetails.profilePic);
  }, [userDetails.profilePic]);
  // useEffect(()=>{
  //   fetchProfile();
  // },[])
  // const fetchProfile = async () => {
  //   try {
  //     const url = API_URL.PROFILE;
  //     const result = await Helper.GetData(url);
  //     if (result.data.status) {
  //       setProfileImg(result.data.data.profilePic)
  //     }
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };
  const handleLogout = () => {
    dispatch(LogedOut());
    window.localStorage.removeItem("userToken");
    window.sessionStorage.removeItem("userToken");
  };
  return (
    <div className="header">
      <Navbar {...args} expand="md">
        <Collapse navbar>
          <Nav className="ms-auto align-items-center" navbar>
            {/* {location.pathname === "/Dashboard" && (
              <div className="searchweb_div">
                <Search className="ficon" />
                <Input
                  type="text"
                  placeholder="Search for recipes, videos or plans..."
                  className="search_input"
                />
              </div>
            )} */}
            <UncontrolledDropdown
              nav
              inNavbar
              className="dropdown-language nav-item"
            >
              <DropdownToggle nav>
                <ReactCountryFlag
                  className="country-flag"
                  countryCode="us"
                  svg
                />
                <span className="ms-1">English</span>
              </DropdownToggle>
              <DropdownMenu end style={{ right: 0, position: "absolute" }}>
                <DropdownItem>
                  <Link>
                    {" "}
                    <ReactCountryFlag
                      className="country-flag"
                      countryCode="us"
                      svg
                    />
                    <span className="ms-1">English</span>
                  </Link>
                </DropdownItem>
                <DropdownItem>
                  <Link>
                    <ReactCountryFlag
                      className="country-flag"
                      countryCode="es"
                      svg
                    />
                    <span className="ms-1">Spanish</span>
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
            <UncontrolledDropdown nav inNavbar className="headerdropdown_menu">
              <DropdownToggle nav>
                <div className="headeruserimg">
                  <img
                    src={profileImg ? S3_URL + profileImg : userimg}
                    alt=""
                  />
                </div>
              </DropdownToggle>
              <DropdownMenu end style={{ right: 0, position: "absolute" }}>
                <DropdownItem>
                  <Link to="/ProfileSetting">
                    <User size={18} /> Profile Setting
                  </Link>
                </DropdownItem>
                <DropdownItem onClick={handleLogout}>
                  <Link to="#">
                    <LogOut size={18} /> Logout
                  </Link>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
};

export default Header;
